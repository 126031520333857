import { HiOutlineMail } from 'react-icons/hi';
import { FaTelegram, FaTwitter } from 'react-icons/fa';
import $ from 'jquery';

$(function () {
    $("#stringMe").on("click", function () {
        var trade = $("#trade");
        var number = $("#number");
        var amount = $("#amount");
        var wall = $("#wall");
        var string = "<p style='word-break: break-all;' id='copytx'>" + number.val() + " for " + amount.val() + " xrd to " + wall.val() + "</p>";
        $(string).appendTo("#stringedUp")
    })
});

function copyToClipboard2(element) {
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val($(element).text()).select();
    document.execCommand("copy");
    $temp.remove()
}
const Instruction = () => {
    return (
        <div className="text-white w-full flex justify-center py-5">
            <div className="md:w-[800px] w-full md:px-600 px-5 text-center">
                <div className="uppercase text-3xl">HOARD</div>
                <div className="mt-10">
                    <div className="text-2xl">Instructions for HOARD Purchase, Listing and Delisting</div><br />
                    <a href="https://www.vikingland.io/collection/HOARD" target="_blank" className="break-words">For sales please visit <b>Vikingland</b> </a>
                    <div className="mt-5 text-sm text-centre text-slate-300 leading-6">
                        <br /> HOARD TOKEN CONSIST OF 10000 UNIQUE NFTS for minting on the Radix DLT. <br /><br />We sold out around May 2022. Visit our <a href="https://t.me/HOARDTOKEN" target="_blank" className="break-words"><b>Telegram Chat</b> for more information</a><br /><br />

                        <a href="https://www.vikingland.io/collection/HOARD" target="_blank" className="break-words">Please goto <b>Vikingland</b> to purchase please in addition to the instructions below.</a>

                       
                    </div>
                </div>
                <div className="mt-10">
                    
                   
                    <div className="mt-10">
                        <div className="text-2xl">List your NFT</div>
                        <div className="mt-5 text-sm text-left text-slate-300 leading-6">

                        If you want to list your NFT for sale on <a href="https://www.vikingland.io/collection/HOARD" target="_blank" className="break-words"> <b>VikingLand marketplace.</b>  Please follow steps below.</a><br /><br />

                        You have to send your NFT token to the collection wallet <br /><br /><b>rdx1qsps4vmz6uh4lmam7ra6hk8rlh0p3wxzp4mwgeas4a2jkv0nl7llzjg2h0ard</b> <br /><br />with transaction message "list (HOARD) for (NFT price)"<br /><br />

                        Example 1:
                        You want to list your HOARD  NFT 10 for 400 XRD. You have to send a HOARD token to the collection wallet with a transaction message "list HOARD 10 for 400 xrd"<br /><br />

                        Example 2:
                        You want to list your HOARD  NFT 666 for 1000 XRD. You have to send a HOARD token to the collection wallet with transaction message "list HOARD 666 for 1000 xrd"<br /><br />

                        IMPORTANT:
                        You must be sure to send the token from the wallet you bought it with. Otherwise, the transaction cannot be assigned.<br /><br />

                        Fees:
                        Please be aware that there is trading fee for using the marketplace and a royalty fee from the NFT creator.

                        
                        </div>
                    </div>
                    <div className="mt-10">
                        <div className="text-2xl">Delisting your NFT</div>
                        <div className="mt-5 text-sm text-left text-slate-300 leading-6">

                        You can also delist your NFT at any time from the marketplace:<br /><br />

                        Send 1 XRD to the collection wallet address <br /><br /><b>rdx1qsps4vmz6uh4lmam7ra6hk8rlh0p3wxzp4mwgeas4a2jkv0nl7llzjg2h0ard</b> <br /><br />with transaction message "delist (HOARD)"<br /><br />

                        Example:
                        You want to delist HOARD 666. You have to send 1 XRD to collection wallet with transaction message "delist HOARD 666"<br /><br />

                        IMPORTANT:
                        You must be sure to delist from the wallet you bought it with. Otherwise, the transaction cannot be assigned.           
                        </div>
                    </div>
                </div>
                <div className="mt-10">
                    <div className="text-2xl">DISCLAIMER</div>
                    <div className="mt-5 text-sm text-centre text-slate-300 leading-6">
                        The HOARD Team will NOT be held responsible if your transactions are incorrect.
                        <br />
                        Please make sure our address is accurate - see below. <br /><br />
                        You are advised to NOT use any ethereum wallets for sending XRD. Please use the Offical Radix Wallet <br />
                        <br /><br />
                        
                    </div>
                </div>
                <div className="mt-10">
                    <div className="text-2xl">FIND US HERE FOR VERIFICATION:</div>
                    <div className="mt-5 text-sm text-centre text-slate-300 leading-6">
                        <a href="https://explorer.radixdlt.com/#/accounts/rdx1qsps4vmz6uh4lmam7ra6hk8rlh0p3wxzp4mwgeas4a2jkv0nl7llzjg2h0ard" target="_blank" className="break-words">HOARD Collection Wallet</a>
                    </div>
                </div>
                <div className="mt-10">
                    <div className="text-2xl">USE THE OFFICIAL RADIX DLT WALLET HERE:</div>
                    <div className="mt-5 text-sm text-centre text-slate-300 leading-6">
                        <a href="https://wallet.radixdlt.com" target="_blank" className="break-words">https://wallet.radixdlt.com</a>
                    </div>
                </div>
                <div className="mt-10">
                    <div className="text-2xl">Only use Radix's Native Token (XRD):</div>
                    <div className="mt-5 text-sm text-centre text-slate-300 leading-6">
                        <div>The official Radix Wallet does NOT except ethereum wrapped Radix Tokens (EXRD).</div>
                        <a href="https://learn.radixdlt.com/article/how-can-i-purchase-xrd-and-exrd-tokens" target="_blank" className="break-words">https://learn.radixdlt.com/article/how-can-i-purchase-xrd-and-exrd-tokens</a>
                    </div>
                </div>
                <div className="mt-10">
                    <div className="text-2xl">Reach Us:</div>
                    <div className="mt-5 text-slate-300 flex flex-row justify-center gap-10 text-3xl">
                        <a href='https://t.me/hoardtokens' target="_blank"><FaTelegram /></a>
                        <a href="https://twitter.com/hoardtoken" target="_blank"><FaTwitter /></a>
                        <a href='mailto:mail@hoardtokens.com'><HiOutlineMail /></a>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Instruction;
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from 'react-infinite-scroll-component';
import { FaCaretUp, FaCaretDown, FaTimes } from 'react-icons/fa';
import Spinner from '../components/spinner';
import { 
  loadMetaData,
  loadTotalSold,
  loadRarityData,
  loadTokenByID,
  loadFirstNameData,
  loadSecondNameData,
  loadCountryData,
  loadMonthData,
  loadDayData,
  loadYearData,
  loadCoinAgeData
} from '../redux/common/commonActions';
import '../styles/home.css';
import { camelCase } from 'lodash';

const BASE_URL = "https://www.hoardtoken.com/HOARD/HOARD_";

const Home = () => {
  const dispatch = useDispatch();
  const common = useSelector((state) => state.common);
  const [modalOpen, setModalOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [modalToken, setModalToken] = useState({});
  const [tokenList, setTokenList] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [filterObj, setFilterObj] = useState({});
  const [datas, setDatas] = useState([]);
  const countPerPage = 100;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    dispatch(loadTotalSold());
    await dispatch(loadMetaData());
    await dispatch(loadRarityData());
    await dispatch(loadFirstNameData());
    await dispatch(loadSecondNameData());
    await dispatch(loadCountryData());
    await dispatch(loadMonthData());
    await dispatch(loadDayData());
    await dispatch(loadYearData());
    await dispatch(loadCoinAgeData());
  }, []);

  useEffect(() => {
    if (!common.isLoadingMetaData) { 
      setDatas(common.metaData.maininfo);
    }
  }, [common.isLoadingMetaData]);

  useEffect(() => {
    if (datas.length > 0)
      fetchTokenList();
  }, [datas])

  const fetchTokenList = (type = 'nextPage') => {
    if (type === 'nextPage') {
      // load next page data
      setTokenList([...tokenList, ...datas.slice((pageIndex - 1) * countPerPage, pageIndex * countPerPage)]);
      setPageIndex((prevState) => prevState + 1);
    }
  }

  const openDetailPopup = async (item) => {    
    await dispatch(loadTokenByID(parseInt(item.hoardToken)));
    setModalToken(item);
    setModalOpen(true);
  }

  const onWalletClick = (address) => {
    window.open(`/wallet?address=${address}`, "_blank");
  }

  const onFilterClick = async (e) => {
    let temp = filterObj;
    const subtrait = e.target.id.split("_")[1];
    const traittype = e.target.id.split("_")[0];
    if(e.target.checked === true){
      if(temp[camelCase(traittype)] == undefined){
        temp[camelCase(traittype)] = [];
        temp[camelCase(traittype)].push(subtrait);
      } else {
        temp[camelCase(traittype)].push(subtrait);
      }
    } else {
      if(temp[camelCase(traittype)].includes(subtrait) === true){
        temp[camelCase(traittype)] = temp[camelCase(traittype)].filter((item, index) => item !== subtrait);
        if(temp[camelCase(traittype)].length == 0){
          delete temp[camelCase(traittype)];
        }
      }
    }
    setFilterObj(temp);
    let data = common.metaData.maininfo;
    if(Object.keys(temp).length != 0){
      Object.keys(temp).map((item, index) => {
        data = data.filter((it, idx) => temp[camelCase(item)].includes(it[camelCase(item)]));
      })
    }
    await setTokenList([]);
    await setPageIndex(1);
    await setDatas([...data]);
  }

  const getCamelCase = (str) => {
    if (str.includes("blocks")) {
      return "blockNumber";
    } else if (str.includes("colour")) {
      return "colours";
    } else if (str.includes("name") != true) {
      return "elementsOutOf10000";
    }
  }

  const getRarityAmount = (item, element) => {
    if (item.toUpperCase() === '1STNAME' && common.firstName.length > 0) {
      return common.firstName.find(ele => ele.firstName === element).rarity; 
    } else if (item.toUpperCase() === '2NDNAME' && common.secondName.length > 0) {
      return common.secondName.find(ele => ele.secondName === element).rarity;  
    } else if(item.toUpperCase() === 'COUNTRY' && common.country.length > 0) {
      return common.country.find(ele => ele.country === element).rarity;  
    } else if(item.toUpperCase() === 'MONTH' && common.month.length > 0){
      return common.month.find(ele => ele.month === element).rarity;  
    } else if(item.toUpperCase() === 'DAY' && common.day.length > 0) {
      return common.day.find(ele => ele.day === element).rarity;  
    } else if(item.toUpperCase() === 'YEAR' && common.year.length > 0) {
      return common.year.find(ele => ele.year === element).rarity;  
    } else if(item.toUpperCase() === 'COINAGE' && common.coinAge.length > 0) {
      return common.coinAge.find(ele => ele.coinAge === element).rarity;  
    } else { 
      const itemIndex = getCamelCase(item);
      let eleData = element;
      let res = "";
      if (eleData.toLowerCase().includes("block")) {
        eleData = eleData.split(' ')[0];
        } else if (eleData.toLowerCase().includes("#")) {
            eleData.replace('#', '');
          }
         
      common.rarityData[itemIndex].map((ele, index) => {
        if (camelCase(ele.name) === item) {
          if (itemIndex === "blockNumber") {
            res = ele.blocksRarity;
          } else if (itemIndex === "colours") {
            res = ele.colourRarity;
          } else if (itemIndex === "elementsOutOf10000") {
            res = ele.elementsRarity;
          }
        }
      });  
      return res;
    }    
  }
console.log('common', common)
  return (
    <div className='mt-16 relative'>
      { common.isLoadingMetaData || common.isLoadingRarityData ? <Spinner /> :
      <>
      <div className='text-white'>
        <div className='w-full text-center'>
          <div className='my-8'>{(common.sold_count) * 1.0 / common.metaData.maininfo.length * 100}% reserved</div>
          {/* Attributes Filter */}
          <div className='flex flex-row items-center justify-center cursor-pointer' onClick={() => setFilterOpen((prevState) => !prevState)}>
            <div className='font-medium'>Filters</div>
            <div className='mt-1 ml-1'>
              {filterOpen ? <FaCaretDown /> : <FaCaretUp />}
            </div>
          </div>
          <div className="w-full flex justify-center">
            {filterOpen && <div className='flex flex-row justify-center filterDown gap-5 flex-wrap w-2/4'>
              {
                Object.keys(common.metaData.traits).map((key, index) => { 
                  if(key !== 'hoardToken') {
                    return (
                      <div key={index}>
                        <div className='text-left font-bold text-[#00FF00]'>{key.toUpperCase()}</div>
                        <div className='h-[110px] w-[200px] overflow-y-auto flex flex-col gap-1 text-left nice-scroll'>
                          {
                            common.metaData.traits[camelCase(key)].map((item, idx) => { 
                              return (
                                <div className='flex flex-row items-center gap-1' key={idx}>
                                  <input type="checkbox" id={key + "_" + item}  onClick={(e) => onFilterClick(e)} className="min-w-[16px] min-h-[16px]" />
                                  <p className='md:max-w-[500px] w-auto' htmlFor={key + "_" + item}>{item}</p>
                                </div>
                              );
                            })
                          }
                        </div>
                      </div>
                    )
                  }
                })
              }
            </div>}
          </div>
        </div>
        {/* Token Image Gallery */}
        <div className=''>
          {common.isLoadingMetaData ? <Spinner /> : 
            <InfiniteScroll
              dataLength={tokenList.length}
              next={fetchTokenList}
              hasMore={true}
              className='flex flex-row flex-wrap mt-4'
            >
              {
                tokenList.map((item, index) => {
                  return (
                    <img
                      key={index}
                      src={BASE_URL + item.hoardToken.toString().padStart(5, 0) + '.png'}
                      className="w-[50%] md:w-[25%] lg:w-[20%] 2xl:w-[10%] cursor-pointer border border-gray-500 border-opacity-80"
                      onClick={() => openDetailPopup(item)}
                      alt=''
                    />
                  )
                })
              }
            </InfiniteScroll>
          }
        </div>
      </div>
      {/* Token Detail Popup */}
      {modalOpen && common.isLoadingToken == false && <div className='fixed inset-0 flex items-center justify-center'>
        <div className='fixed inset-0 modalBack bg-[#000000] bg-opacity-95 z-0' onClick={() => setModalOpen(false)}></div>
        <div className='elementFadeUp relative w-[100px] min-h-[100px] bg-[#000000] border border-black border-opacity-30 rounded-lg z-10 flex flex-col text-center px-5 pt-12 pb-5 items-center detailInfo'>
          <img src={BASE_URL + modalToken.hoardToken.toString().padStart(5, 0) + '.png'} className="w-[350px]" />
          <div className="text-[#FFFFFF] text-lg mt-2 font-bold">Hoard No.</div>
          <div className="text-[#FFFFFF] text-3xl font-bold">{modalToken.hoardToken}</div>
          <div className="text-[#00FF00] text-base mt-2">RESERVED BY:</div>
          <div className="text-[#FFFFFF] text-base w-[320px] mt-2 cursor-pointer break-all" onClick={() => onWalletClick(common.token.owner)}>{common.token.owner}</div>
          <div className="w-full px-6 font-bold text-[#334455] flex flex-row justify-between mt-5">
            
          </div>
          
          <div className= "text-[#FF0000] text-base mt-4" >
            <a href={`/info?number=${parseInt(modalToken.hoardToken)}`} target="_blank" >*CLICK HERE FOR TRAITS*</a>
          </div>
          <div className='w-full flex justify-end absolute top-4 right-4 text-lg cursor-pointer' onClick={() => setModalOpen(false)}>
            <FaTimes />
          </div>
        </div>
      </div>}
      </>
      }
    </div>
  );
}

export default Home;
import { Oval } from  'react-loader-spinner';

const Spinner = () => {
  
  return (
    <div className='absolute inset-0 w-full h-full flex items-center justify-center'>
      <Oval color='white' secondaryColor='white' strokeWidth={3} height={80} width={80} />
    </div>
  );
}

export default Spinner;
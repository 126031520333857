import axios from "axios";
import {
  camelCase,
  shuffle
} from "lodash";

export const loadMetaData = () => {
  return async dispatch => {
    dispatch({ type: 'START_LOAD_METADATA' });
    
    function onSuccess(success) {
      let rawData = success.data.values;
      let keys = rawData[0].slice(0, rawData[0].length);
      let metaData = {};
      let traits = {};
      let maininfo = [];
      rawData.forEach((o, idx) => {
        if (idx > 0) {
          let item = {};
          keys.forEach((key, kIdx) => {
            item[camelCase(key)] =  kIdx < o.length ? o[kIdx] : '';
            // if(kIdx > 0 && key.toLowerCase().search('COLOUR'.toLowerCase()) === -1){
              if(traits[camelCase(key)] == undefined && (o[kIdx] != undefined && o[kIdx].length > 1)){
                traits[camelCase(key)] = [];
                traits[camelCase(key)][0] = o[kIdx];
              } else if((o[kIdx] != undefined && o[kIdx].length > 1)){
                if(traits[camelCase(key)].indexOf(o[kIdx]) <= -1){
                  traits[camelCase(key)].push(o[kIdx]);
                }
              // }
            }
          });
          maininfo.push(item);
        }
      }); 
      maininfo = shuffle(maininfo); 
      // maininfo.sort((a, b) => parseInt((a.hoardToken)) - parseInt((b.hoardToken)));
      metaData[camelCase("maininfo")] = maininfo;
      metaData[camelCase("traits")] = traits;
      dispatch({ type: 'LOAD_METADATA', payload: metaData });
      return metaData;
    }
    function onError(error) {
      return error;
    }
    try {
      const success = await axios.get('https://sheets.googleapis.com/v4/spreadsheets/1djcGULCa_ISNhWkIx89miaXK3kkU-lSPZST9_pKkyAk/values/HOARD_TOKEN?alt=json&key=AIzaSyAw_5Iwc5cZqNSZtPtkpzhkAn6Adyd97xY');
      return onSuccess(success);
    } catch (error) {
      return onError(error);
    }
  }
};

export const loadRarityData = () => {
  return async dispatch => {
    dispatch({ type: 'START_LOAD_RARITYDATA' });

    function onSuccess(success) { 
      let rawData = success.data.values; 
      let keys = [];
      let rarityData = {};
      rawData.map((item, index) => {
        if (index > 0) {
          if(rawData[index - 1].length === 0) {
            keys.push(camelCase(item[0]));
          }
        }
      });
      rawData.map((item, index) => {
        if (keys.includes(camelCase(item[0])) && index > 0 && rawData[index - 1].length === 0) {
          item.map((ele, ind) => {
            if (ind > 0) {
              let itemat = {};
              itemat[camelCase("name")] = ele;
              itemat[camelCase(rawData[index + 1][0])] = rawData[index + 1][ind];
              itemat[camelCase(rawData[index + 2][0])] = rawData[index + 2][ind];
              itemat[camelCase(rawData[index + 3][0])] = rawData[index + 3][ind];
              if (rarityData[camelCase(item[0])] == undefined) {
                rarityData[camelCase(item[0])] = [];
                rarityData[camelCase(item[0])][0] = itemat;
              } else {
                rarityData[camelCase(item[0])].push(itemat);
              }
            }
          });
        }
      });
      dispatch({ type: 'LOAD_RARITYDATA', payload: rarityData });
      return rarityData;
    }
    function onError(error) {
      return error;
    }
    try {
      const success = await axios.get('https://sheets.googleapis.com/v4/spreadsheets/1djcGULCa_ISNhWkIx89miaXK3kkU-lSPZST9_pKkyAk/values/RARITY?alt=json&key=AIzaSyAw_5Iwc5cZqNSZtPtkpzhkAn6Adyd97xY&majorDimension=COLUMNS');
      return onSuccess(success);
    } catch (error) {
      return onError(error);
    }
  }
};

export const loadTokenListByOffset = (offset) => { 
  return async (dispatch, getState) => {
    dispatch({type: 'START_LOAD_TOKENLIST'});
    let res = [];
    fetch(`https://vikingland-server-new.vercel.app/api/nftsCollection/Hoard/All/empty/Price%20low%20to%20high/${offset}`, { method: 'get' })
    .then(response => response.json())
    .then(data => {
      for (const [key, value] of Object.entries(data)) {
        res[parseInt(key - offset)] = value;
      }
      res = getState().common.tokenlist.concat(res);
      
      dispatch({type: 'LOAD_TOKENLIST', payload: res});
      return res;
    });
  }
};

export const loadTokenListByWallet = (wallet) => {
  return async (dispatch) => {
    dispatch({type: 'START_LOAD_WALLETTOKEN'});
    let res = [];
    fetch(`https://vikingland-server-new.vercel.app/api/userNfts/${wallet}/HOARD/All`, { method: 'get' })
    .then(response => response.json())
    .then(data => {
      if (data.status != 'failed') {
        let i = 0;
        for (const [key, value] of Object.entries(data)) {
          res[i++] = value;
        }
        dispatch({type: 'LOAD_WALLETTOKEN', payload: res});
        return data;
      }
      dispatch({type: 'LOAD_WALLETTOKEN', payload: res});
      return null;
    })
  }
};

export const loadTokenByID = (id) => {
  return async dispatch => {
    dispatch({type: 'START_LOAD_TOKEN'});
    fetch(`https://vikingland-server-new.vercel.app/api/collection/HOARD/HOARD%20${id}`)
	  .then(response => response.json())
	  .then(data => {
      dispatch({type: 'LOAD_TOKEN', payload: data});
      return data;
    });
  }
}

export const loadTotalSold = () => {
  return async dispatch => {
    dispatch({type: 'START_LOAD_SOLD'});
    const res = await axios.get("https://vikingland-server-new.vercel.app/api/Reserved/HOARD/rdx1qsps4vmz6uh4lmam7ra6hk8rlh0p3wxzp4mwgeas4a2jkv0nl7llzjg2h0ard");

    dispatch({type: 'LOAD_SOLD', payload: res.data.Reserved});
  }
}

export const loadFirstNameData = () => {
  return async dispatch => {
    dispatch({ type: 'START_LOAD_FIRSTNAME' });

    function onSuccess(success) { 
      let rawData = success.data.values; 
      let firstNameData = [];
      if (rawData.length > 0) {
        rawData.map((element, index) => { 
          if (index === 0) {
            element.map((ele, idx) => {
              let item = {};
              if (idx > 1) {
                item['firstName'] = ele;
                firstNameData.push(item)
              }
            });
          } else if (index === 4) {
            element.map((ele, idx) => { 
              if (idx > 1) {
                firstNameData[idx-2]['rarity'] = ele;
              }
            })
          }          
        })
      }       
      dispatch({ type: 'LOAD_FISTNAME', payload: firstNameData });
      return firstNameData;
    }
    function onError(error) {
      return error;
    }
    try {
      const success = await axios.get('https://sheets.googleapis.com/v4/spreadsheets/1djcGULCa_ISNhWkIx89miaXK3kkU-lSPZST9_pKkyAk/values/FirstName?alt=json&key=AIzaSyAw_5Iwc5cZqNSZtPtkpzhkAn6Adyd97xY&majorDimension=COLUMNS');
      return onSuccess(success);
    } catch (error) {
      return onError(error);
    }
  }
};

export const loadSecondNameData = () => {
  return async dispatch => {
    dispatch({ type: 'START_LOAD_SECONDNAME' });

    function onSuccess(success) { 
      let rawData = success.data.values;
      let secondNameData = [];
      if (rawData.length > 0) {
        rawData.map((element, index) => { 
          if (index === 0) {
            element.map((ele, idx) => {
              let item = {};
              if (idx > 1) {
                item['secondName'] = ele;
                secondNameData.push(item)
              }
            });
          } else if (index === 4) {
            element.map((ele, idx) => { 
              if (idx > 1) {
                secondNameData[idx-2]['rarity'] = ele;
              }
            })
          }          
        })
      }       
      dispatch({ type: 'LOAD_SECONDNAME', payload: secondNameData });
      return secondNameData;
    }
    function onError(error) {
      return error;
    }
    try {
      const success = await axios.get('https://sheets.googleapis.com/v4/spreadsheets/1djcGULCa_ISNhWkIx89miaXK3kkU-lSPZST9_pKkyAk/values/SurName?alt=json&key=AIzaSyAw_5Iwc5cZqNSZtPtkpzhkAn6Adyd97xY&majorDimension=COLUMNS');
      return onSuccess(success);
    } catch (error) {
      return onError(error);
    }
  }
};

export const loadCountryData = () => {
  return async dispatch => {
    dispatch({ type: 'START_LOAD_COUNTRY' });

    function onSuccess(success) { 
      let rawData = success.data.values;
      let countryData = [];
      if (rawData.length > 0) {
        rawData.map((element, index) => { 
          if (index === 0) {
            element.map((ele, idx) => {
              let item = {};
              if (idx > 1) {
                item['country'] = ele;
                countryData.push(item)
              }
            });
          } else if (index === 4) {
            element.map((ele, idx) => { 
              if (idx > 1) {
                countryData[idx-2]['rarity'] = ele;
              }
            })
          }          
        })
      }       
      dispatch({ type: 'LOAD_COUNTRY', payload: countryData });
      return countryData;
    }
    function onError(error) {
      return error;
    }
    try {
      const success = await axios.get('https://sheets.googleapis.com/v4/spreadsheets/1djcGULCa_ISNhWkIx89miaXK3kkU-lSPZST9_pKkyAk/values/CountryFound?alt=json&key=AIzaSyAw_5Iwc5cZqNSZtPtkpzhkAn6Adyd97xY&majorDimension=COLUMNS');
      return onSuccess(success);
    } catch (error) {
      return onError(error);
    }
  }
};

export const loadMonthData = () => {
  return async dispatch => {
    dispatch({ type: 'START_LOAD_MONTH' });

    function onSuccess(success) { 
      let rawData = success.data.values; 
      let monthData = [];
      if (rawData.length > 0) {
        rawData.map((element, index) => { 
          if (index === 0) {
            element.map((ele, idx) => {
              let item = {};
              if (idx > 1) {
                item['month'] = ele;
                monthData.push(item)
              }
            });
          } else if (index === 4) {
            element.map((ele, idx) => { 
              if (idx > 1) {
                monthData[idx-2]['rarity'] = ele;
              }
            })
          }          
        })
      }       
      dispatch({ type: 'LOAD_MONTH', payload: monthData });
      return monthData;
    }
    function onError(error) {
      return error;
    }
    try {
      const success = await axios.get('https://sheets.googleapis.com/v4/spreadsheets/1djcGULCa_ISNhWkIx89miaXK3kkU-lSPZST9_pKkyAk/values/MonthFound?alt=json&key=AIzaSyAw_5Iwc5cZqNSZtPtkpzhkAn6Adyd97xY&majorDimension=COLUMNS');
      return onSuccess(success);
    } catch (error) {
      return onError(error);
    }
  }
};

export const loadDayData = () => {
  return async dispatch => {
    dispatch({ type: 'START_LOAD_DAY' });

    function onSuccess(success) { 
      let rawData = success.data.values;
      let dayData = [];
      if (rawData.length > 0) {
        rawData.map((element, index) => { 
          if (index === 0) {
            element.map((ele, idx) => {
              let item = {};
              if (idx > 1) {
                item['day'] = ele;
                dayData.push(item)
              }
            });
          } else if (index === 4) {
            element.map((ele, idx) => { 
              if (idx > 1) {
                dayData[idx-2]['rarity'] = ele;
              }
            })
          }          
        })
      }       
      dispatch({ type: 'LOAD_DAY', payload: dayData });
      return dayData;
    }
    function onError(error) {
      return error;
    }
    try {
      const success = await axios.get('https://sheets.googleapis.com/v4/spreadsheets/1djcGULCa_ISNhWkIx89miaXK3kkU-lSPZST9_pKkyAk/values/DayOfMonthFound?alt=json&key=AIzaSyAw_5Iwc5cZqNSZtPtkpzhkAn6Adyd97xY&majorDimension=COLUMNS');
      return onSuccess(success);
    } catch (error) {
      return onError(error);
    }
  }
};

export const loadYearData = () => {
  return async dispatch => {
    dispatch({ type: 'START_LOAD_YEAR' });

    function onSuccess(success) { 
      let rawData = success.data.values;
      let yearData = [];
      if (rawData.length > 0) {
        rawData.map((element, index) => { 
          if (index === 0) {
            element.map((ele, idx) => {
              let item = {};
              if (idx > 1) {
                item['year'] = ele;
                yearData.push(item)
              }
            });
          } else if (index === 4) {
            element.map((ele, idx) => { 
              if (idx > 1) {
                yearData[idx-2]['rarity'] = ele;
              }
            })
          }          
        })
      }       
      dispatch({ type: 'LOAD_YEAR', payload: yearData });
      return yearData;
    }
    function onError(error) {
      return error;
    }
    try {
      const success = await axios.get('https://sheets.googleapis.com/v4/spreadsheets/1djcGULCa_ISNhWkIx89miaXK3kkU-lSPZST9_pKkyAk/values/YearFound?alt=json&key=AIzaSyAw_5Iwc5cZqNSZtPtkpzhkAn6Adyd97xY&majorDimension=COLUMNS');
      return onSuccess(success);
    } catch (error) {
      return onError(error);
    }
  }
};

export const loadCoinAgeData = () => {
  return async dispatch => {
    dispatch({ type: 'START_LOAD_COINAGE' });

    function onSuccess(success) { 
      let rawData = success.data.values; console.log("rawData ", rawData)
      let coinAgeData = [];
      if (rawData.length > 0) {
        rawData.map((element, index) => { 
          if (index === 0) {
            element.map((ele, idx) => {
              let item = {};
              if (idx > 1) {
                item['coinAge'] = ele;
                coinAgeData.push(item)
              }
            });
          } else if (index === 4) {
            element.map((ele, idx) => { 
              if (idx > 1) {
                coinAgeData[idx-2]['rarity'] = ele;
              }
            })
          }          
        })
      }       
      dispatch({ type: 'LOAD_COINAGE', payload: coinAgeData });
      return coinAgeData;
    }
    function onError(error) {
      return error;
    }
    try {
      const success = await axios.get('https://sheets.googleapis.com/v4/spreadsheets/1djcGULCa_ISNhWkIx89miaXK3kkU-lSPZST9_pKkyAk/values/CoinAge?alt=json&key=AIzaSyAw_5Iwc5cZqNSZtPtkpzhkAn6Adyd97xY&majorDimension=COLUMNS');
      return onSuccess(success);
    } catch (error) {
      return onError(error);
    }
  }
};

/* eslint-disable array-callback-return */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  loadMetaData,
  loadTokenByID,
  loadRarityData,
  loadFirstNameData,
  loadSecondNameData,
  loadCountryData,
  loadMonthData,
  loadDayData,
  loadYearData,
  loadCoinAgeData
} from "../redux/common/commonActions";
import Spinner from "../components/spinner";
import { camelCase } from "lodash";
import { param } from "jquery";

const BASE_URL = "https://www.hoardtoken.com/HOARD/HOARD_";

const Info = () => {
  const dispatch = useDispatch();
  const [id, setId] = useState(0);
  const [tokenId, setTokenId] = useState(0);
  const common = useSelector((state) => state.common);

  useEffect(() => {    
    const fetchData = async () => {
      const params = new URLSearchParams(window.location.search);
      setTokenId(params.get("number"));
      dispatch(loadTokenByID(params.get("number")));
      await dispatch(loadRarityData());
      await dispatch(loadMetaData());
      await dispatch(loadFirstNameData());
      await dispatch(loadSecondNameData());
      await dispatch(loadCountryData());
      await dispatch(loadMonthData());
      await dispatch(loadDayData());
      await dispatch(loadYearData());
      await dispatch(loadCoinAgeData());
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (!common.isLoadingMetaData) {
      const params = new URLSearchParams(window.location.search);
      const data = common.metaData.maininfo;
      if (data) {
        const id = data.findIndex((item, idx) => {
          return item.hoardToken === params.get("number");
        });
        setId(id);
      }
    }
  }, [common.isLoadingMetaData]);

  const onWalletClick = (address) => {
    window.open(`/wallet?address=${address}`, "_self");
  };

  const getCamelCase = (str) => {
    if (str.includes("blocks")) {
      return "blockNumber";
    } else if (str.includes("colour")) {
      return "colours";
    } else if (str.includes("name") !==true) {
      return "elementsOutOf10000";
    } else {
      return "none";
    }
  };

  const getTotalRarityAmount = () => {
    let result = 1.0;
    Object.keys(common.metaData.maininfo[id]).map((item, index) => {
      const itemIndex = getCamelCase(item.toLowerCase());
      if (itemIndex !== "none") {
        if (common.metaData.maininfo[id][camelCase(item)].length > 0) {
          let amount = common.metaData.maininfo[id][camelCase(item)];
          if (amount.toLowerCase().includes("block")) {
            amount = amount.split(" ")[0];
          }

          common.rarityData[itemIndex].map((ele, ind) => {
            if (ele.name.toLowerCase() === amount.toLowerCase()) {
              if (itemIndex === "blockNumber") {
                result += parseFloat(ele.blocksRarity.split("%")[0]);
                // result = result * parseFloat(ele.blocksRarity.split('%')[0]) / 100.0;
              } else if (itemIndex === "colours") {
                result += parseFloat(ele.colourRarity.split("%")[0]);
                // result = result * parseFloat(ele.colourRarity.split('%')[0]) / 100.0;
              } else if (itemIndex === "elementsOutOf10000") {
                result += parseFloat(ele.elementsRarity.split("%")[0]);
                // result = result * parseFloat(ele.elementsRarity.split('%')[0]) / 100.0;
              }
            }
          });
        }
      }
    });

    return result;
  };

  const getRarityAmount = (item, element) => {
    if (item.toUpperCase() === '1STNAME' && common.firstName.length > 0) {
      return common.firstName.find(ele => ele.firstName === element).rarity; 
    } else if (item.toUpperCase() === '2NDNAME' && common.secondName.length > 0) {
      return common.secondName.find(ele => ele.secondName === element).rarity;  
    } else if(item.toUpperCase() === 'COUNTRY' && common.country.length > 0) {
      return common.country.find(ele => ele.country === element).rarity;  
    } else if(item.toUpperCase() === 'MONTH' && common.month.length > 0){
      return common.month.find(ele => ele.month === element).rarity;  
    } else if(item.toUpperCase() === 'DAY' && common.day.length > 0) {
      return common.day.find(ele => ele.day === element).rarity;  
    } else if(item.toUpperCase() === 'YEAR' && common.year.length > 0) {
      return common.year.find(ele => ele.year === element).rarity;  
    } else if(item.toUpperCase() === 'COINAGE' && common.coinAge.length > 0) {
      return common.coinAge.find(ele => ele.coinAge === element).rarity;  
    } else {
      const itemIndex = getCamelCase(item);
      let eleData = element;
      let res = "";
      if (eleData.toLowerCase().includes("block")) {
        eleData = eleData.split(" ")[0];
      } else if (eleData.toLowerCase().includes("#")) {
        eleData.replace("#", "");
      }

      common.rarityData[itemIndex].map((ele, index) => {
        if (camelCase(ele.name) === item) {
          if (itemIndex === "blockNumber") {
            res = ele.blocksRarity;
          } else if (itemIndex === "colours") {
            res = ele.colourRarity;
          } else if (itemIndex === "elementsOutOf10000") {
            res = ele.elementsRarity;
          }
        }
      });
      return res;
    }
  };
  let temp = 0;

  return (
    <div className="w-full flex justify-center mt-12">
      {id <= 0 ? (
        <Spinner />
      ) : (
        <div className="md:w-[606px] w-full py-4 px-4 md:px-32 mt-8 infoContainer text-center text-white flex flex-col items-center">
          <img
            src={
              BASE_URL +
              common.metaData.maininfo[id].hoardToken
                .toString()
                .padStart(5, 0) +
              ".png"
            }
            className="w-[250px]"
            alt=""
          />
          <div className="text-lg mt-2 font-bold">Hoard No.</div>
          <div className="text-3xl font-bold">{tokenId}</div>
          <div className="text-xl font-bold w-[200px] text-ellipsis whitespace-nowrap">
            {common.metaData.maininfo[id]["1stName"]}{" "}
            {common.metaData.maininfo[id]["2ndName"]}
          </div>
          <div className="text-[#00FF00] text-base mt-2">RESERVED BY:</div>
          <div
            className="text-[#FFFFFF] text-base mt-2 cursor-pointer break-words w-full"
            onClick={() => onWalletClick(common.token.owner)}
          >
            {common.token.owner}
          </div>
          <div className="mt-3">Traits</div>
          <div className="w-full mt-2 flex flex-col border border-solid border-white border-opacity-5">
            <div className="w-full flex flex-row justify-between font-bold text-sm py-1">
              <div style={{ width: "33%", textAlign: "start" }}>Trait</div>
              <div style={{ width: "33%", textAlign: "center" }}>Value</div>
              <div style={{ width: "33%", textAlign: "end" }}>Rarity</div>
            </div>
            {Object.keys(common.metaData.maininfo[id]).map((item, index) => {
              if (
                index > 0 &&
                item.includes("colour") !== true &&
                item.includes("name") !== true &&
                common.metaData.maininfo[id][camelCase(item)].length > 1
              ) {
                return (
                  <div
                    className={
                      temp++ % 2 === 1
                        ? "w-full flex flex-row justify-between bg-[#F0E5E5] text-black text-sm py-1 px-2"
                        : "w-full flex flex-row justify-between text-sm py-1 px-2"
                    }
                    key={index}
                  >
                    <div style={{ width: "33%", textAlign: "start" }}>
                      {item.toUpperCase()}
                    </div>
                    <div style={{ width: "33%", textAlign: "center" }}>
                      {common.metaData.maininfo[id][camelCase(item)].length >
                      1 ? (
                        <div>
                          {common.metaData.maininfo[id][camelCase(item)]}
                        </div>
                      ) : (
                        <div>None</div>
                      )}
                    </div>
                    <div style={{ width: "33%", textAlign: "end" }}>
                      {common.metaData.maininfo[id][camelCase(item)].length >
                      1 ? (
                        <div>
                          {getRarityAmount(
                            item,
                            common.metaData.maininfo[id][camelCase(item)]
                          )}
                        </div>
                      ) : (
                        <div>None</div>
                      )}
                    </div>
                  </div>
                );
              }
            })}
          </div>
          
         
        </div>
      )}
    </div>
  );
};

export default Info;

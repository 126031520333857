const initialState = {
  metaData: {},
  isLoadingMetaData: true,
  isLoadingTokenList: true,
  isLoadingToken: true,
  isLoadingWalletTokenList: true,
  tokenlist: [],
  walletTokenList: [],
  token: {},
  sold_count: 0,
  isLoadingRarityData: false,
  rarityData: [],
  isLoadingFirstName: false,
  firstName: [],
  isLoadingSecondName: false,
  secondName: [],
  isLoadingCountry: false,
  country: [],
  isLoadingMonth: false,
  month: [],
  isLoadingDay: false,
  day: [],
  isLoadingYear: false,
  year: [],
  isLoadingCoinAge: false,
  coinAge: []
};

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case "START_LOAD_METADATA":
      return {
        ...state,
        isLoadingMetaData: true
      };
    case "LOAD_METADATA":
      return {
        ...state,
        metaData: action.payload,
        isLoadingMetaData: false
      };
    case 'START_LOAD_TOKENLIST':
      return {
        ...state,
        isLoadingTokenList: true
      };
    case 'START_LOAD_RARITYDATA':
      return {
        ...state,
        isLoadingRarityData: true
      };
    case 'LOAD_RARITYDATA':
      return {
        ...state,
        isLoadingRarityData: false,
        rarityData: action.payload
      }
    case 'LOAD_TOKENLIST':
      return {
        ...state,
        tokenlist: action.payload,
        isLoadingTokenList: false
      };
    case 'START_LOAD_TOKEN':
      return {
        ...state,
        isLoadingToken: true
      }
    case 'LOAD_TOKEN':
      return {
        ...state,
        isLoadingToken: false,
        token: action.payload
      }
    case 'START_LOAD_WALLETTOKEN':
      return {
        ...state,
        isLoadingWalletTokenList: true,
      }
    case 'LOAD_WALLETTOKEN':
      return {
        ...state,
        walletTokenList: action.payload,
        isLoadingWalletTokenList: false
      }
    case 'LOAD_SOLD':
      return {
        ...state,
        sold_count: action.payload
      }
    case 'LOAD_FISTNAME':
        return {
          ...state,
          isLoadingFirstName: false,
          firstName: action.payload
      }
    case 'START_LOAD_FIRSTNAME':
      return { 
        ...state,
        isLoadingFirstName: true
      };
    case 'LOAD_SECONDNAME':
        return {
          ...state,
          isLoadingSecondName: false,
          secondName: action.payload
      }
    case 'START_LOAD_SECONDNAME':
      return { 
        ...state,
        isLoadingSecondName: true
      };
    case 'LOAD_COUNTRY':
        return {
          ...state,
          isLoadingCountry: false,
          country: action.payload
      }
    case 'START_LOAD_COUNTRY':
      return { 
        ...state,
        isLoadingCountry: true
      };
    case 'LOAD_MONTH':
        return {
          ...state,
          isLoadingMonth: false,
          month: action.payload
      }
    case 'START_LOAD_MONTH':
      return { 
        ...state,
        isLoadingMonth: true
      };
    case 'LOAD_DAY':
        return {
          ...state,
          isLoadingDay: false,
          day: action.payload
      }
    case 'START_LOAD_MONTH':
      return { 
        ...state,
        isLoadingDay: true
      };
    case 'LOAD_YEAR':
        return {
          ...state,
          isLoadingYear: false,
          year: action.payload
      }
    case 'START_LOAD_YEAR':
      return { 
        ...state,
        isLoadingYear: true
      };
    case 'LOAD_COINAGE':
        return {
          ...state,
          isLoadingCoinAge: false,
          coinAge: action.payload
      }
    case 'START_LOAD_COINAGE':
      return { 
        ...state,
        isLoadingCoinAge: true
      };
    default:
      return state;
  }
};

export default commonReducer;

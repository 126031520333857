import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Home from './pages/Home';
import Header from './components/Header';
import Info from './pages/Info';
import About from './pages/About';
import Instruction from './pages/Instruction';
import Wallet from './pages/Wallet';

function App() {

  return (
    <Router>
      <div className='overflow-x-hidden'>
        <Header />
        
        <Switch>
          <Route path="/wallet">
            <Wallet />
          </Route>
          <Route path="/info">
            <Info />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/instructions">
            <Instruction />
          </Route>
          <Route path="/">
            <Home />
          </Route>
          <Route path="/home">
            <Home />
          </Route>
        </Switch>

      </div>
    </Router>
  );
}

export default App;
